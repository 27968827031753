<template>
  <DialogClose
    :as-child="asChild"
    :class="styles({ variant, size, condensed, class: props.class })"
  >
    <slot />
  </DialogClose>
</template>

<script lang="ts" setup>
import { DialogClose } from "radix-vue";
import { type Props, styles } from "../Button/config";

const props = defineProps<Props & { asChild?: boolean }>();
</script>
../Button/config
