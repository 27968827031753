<template>
  <td :class="styles({ class: props.class })">
    <slot></slot>
  </td>
</template>

<script lang="ts" setup>
const props = defineProps<{
  class?: any;
}>();

const styles = tv({
  base: "whitespace-nowrap py-2.5 px-4 align-middle",
});
</script>
