import { watchDebounced } from "@vueuse/core";
import type { StreamlabsSocketEvents } from "./auth.store";
import { Source, getUniqueSourceName } from "~/lib/themes/obs";
import { STREAMLABELS_SETTING_KEY } from "~/stores/obs.store";
import Bindings from "~/lib/bindings";
import type { PSource } from "~/lib/obs/sources";
import { useStreamlabsFetch } from "~/lib/streamlabs-api";

export const useStreamLabelsStore = defineStore(
  "stream-labels",
  () => {
    console.log("stream-labels.store");
    const apiStore = useApiStore();

    const authStore = useAuthStore();
    const obs = useObsStore();

    const labelValues = ref<Record<string, string>>({});
    const pinnedLabels = ref<{ [file: string]: boolean }>({});

    const patchStreamLabelValues = (data?: Record<string, string | number>) => {
      if (!data) {
        return;
      }

      // todo: this feels like a hack, but SL api returns new lines with
      // \\n for new lines and not \n
      const cleansedData = Object.entries(data).reduce(
        (carry, [k, v]) => {
          carry[k] = v.toString().replace(/\\n/g, `\n`);
          return carry;
        },
        {} as Record<string, string>,
      );

      labelValues.value = {
        ...labelValues.value,
        ...cleansedData,
      };
    };

    const onStreamlabels = (e: StreamlabsSocketEvents["streamlabels"]) => {
      patchStreamLabelValues(e.message);
    };

    // watch our websocket for `streamlabels` events
    authStore.socket.on("streamlabels", onStreamlabels);

    const groupedLabelSources = computed(() => {
      const groups: Record<string, PSource[]> = {};

      if (!obs.streamLabelSources) {
        return groups;
      }

      for (const source of obs.streamLabelSources) {
        if (!source.settings) {
          continue;
        }

        const streamLabelName: string =
          source.settings[STREAMLABELS_SETTING_KEY];

        if (!groups[streamLabelName]) {
          groups[streamLabelName] = [];
        }

        groups[streamLabelName].push(source);
      }

      return groups;
    });

    const appSettings = apiStore.streamLabels.appSettings;
    const settings = apiStore.streamLabels.settings;
    const files = apiStore.streamLabels.files;

    watch(
      () => files.data,
      (files) => {
        patchStreamLabelValues(files?.data);
      },
    );

    const categories = {
      tips: {
        name: "Streamlabs: Tips & Donations",
      },
      facebook: {
        name: "Facebook",
      },
      twitch: {
        name: "Twitch",
      },
      youtube: {
        name: "YouTube",
      },
      cheers: {
        name: "Twitch: Bits & Cheers",
      },
      subscribers: {
        name: "Twitch: Subscribers",
      },
      followers: {
        name: "Twitch: Followers",
      },
      other: {
        name: "Other",
      },
    };

    const nameToCategory = (
      name: string,
      appSettingsKey: string,
      grouping: string,
    ): keyof typeof categories => {
      // switch (grouping) {
      //   case "facebook":
      //     return "facebook";
      //   case "twitch":
      //     return "twitch";
      //   case "youtube":
      //     return "youtube";
      // }

      if (/facebook/i.test(name) || /facebook/i.test(appSettingsKey)) {
        return "facebook";
      } else if (/twitch/i.test(name) || /twitch/i.test(appSettingsKey)) {
        return "twitch";
      } else if (/youtube/i.test(name) || /youtube/i.test(appSettingsKey)) {
        return "youtube";
      } else if (/member_gift/i.test(name)) {
        return "youtube";
      } else if (/donat/i.test(name)) {
        return "tips";
      } else if (/(subscrib|sub_gift)/i.test(name)) {
        return "subscribers";
      } else if (/cheer/i.test(name)) {
        return "cheers";
      }

      console.warn("unknown stream label categoriy", { name, appSettingsKey });

      return "other";
    };

    const items = computed(() => {
      const items = [];

      if (!settings.data) {
        return [];
      }

      if (!appSettings.data) {
        return [];
      }

      for (const [grouping, categories] of Object.entries(appSettings.data)) {
        for (const [categoryName, category] of Object.entries(categories)) {
          for (const file of category.files) {
            if (file.name.includes("train")) {
              continue;
            }

            const value = labelValues.value[file.name];
            if (value === undefined) {
              continue;
            }

            items.push({
              label: file.label,
              name: file.name,
              category: nameToCategory(file.name, categoryName, grouping),
              value,
              settings: settings.data[file.name],
              settings_extended: file.settings,
              sources: groupedLabelSources.value[file.name] || [],
              pinned: !!pinnedLabels.value[file.name],
            });
          }
        }
      }

      return items;
    });

    type TTT = (typeof items.value)[number];

    const keyedItems = computed(() => {
      return items.value.reduce(
        (keyed, item) => {
          keyed[item.name] = item;
          return keyed;
        },
        {} as { [name: string]: TTT },
      );
    });

    const pinLabel = (item: TTT) => {
      pinnedLabels.value[item.name] = true;
    };

    const unpinLabel = (item: TTT) => {
      delete pinnedLabels.value[item.name];
    };

    const addLabelToScene = async (streamLabel: TTT, sceneName?: string) => {
      if (sceneName) {
        await Bindings.obs.set_current_scene(sceneName);
      }

      await Source.create(
        "text_gdiplus",
        await getUniqueSourceName(streamLabel.label),
        {
          [STREAMLABELS_SETTING_KEY]: streamLabel.name,
          text: streamLabel.value || "",
        },
      );

      obs.bringToFront();
    };

    // watch for stream label sources to change, if they do, update their texts
    watchDebounced(
      [() => obs.streamLabelSources, items],
      async () => {
        if (!obs.streamLabelSources) {
          return;
        }

        for (const source of obs.streamLabelSources) {
          if (!source.settings) {
            continue;
          }

          const streamLabelName: string =
            source.settings[STREAMLABELS_SETTING_KEY];

          const streamLabel = items.value.find(
            ({ name }) => name === streamLabelName,
          );

          if (!streamLabel) {
            continue;
          }

          await source.setSettings({
            ...source.settings,
            text: streamLabel.value,
          });
        }
      },
      {
        debounce: 500,
        immediate: true,
      },
    );

    const hasHasImportableStreamLabels = ref(false);

    const importableStreamLabels = computed(() => {
      if (!obs.sources) {
        return [];
      }

      const items = [];

      for (const source of obs.sources) {
        if (!source.settings) {
          continue;
        }

        if (!source.settings.read_from_file) {
          continue;
        }

        const filePath: string | undefined = source.settings.file;

        if (!filePath) {
          continue;
        }

        const fileName = filePath.split(/[\\/]/).pop() || "";
        const labelName = fileName.split(".").slice(0, -1).join(".");

        const label = keyedItems.value[labelName];

        if (!label) {
          continue;
        }

        hasHasImportableStreamLabels.value = true;

        items.push({
          source,
          label,
          filePath,
          fileName,
          labelName,
        });
      }

      return items;
    });

    const hasImportableStreamLabels = computed(() => {
      return importableStreamLabels.value.length > 0;
    });

    const doImportStreamLabels = async () => {
      console.log("doImportStreamLabels");

      for (const item of importableStreamLabels.value) {
        const settings = {
          [STREAMLABELS_SETTING_KEY]: item.label.name,
          read_from_file: false,
        };

        console.log(item.source);

        console.log(
          `importing source:${item.source.name} (${item.fileName}) -> ${item.label.name}`,
        );

        await Source.update(item.source.name, settings);
      }
    };

    const restartSession = async () =>
      await useStreamlabsFetch<unknown>(
        "/api/v5/slobs/stream-labels/restart-session",
      );

    return {
      // refs
      pinnedLabels,

      // computeds
      items,
      keyedItems,
      categories,
      importableStreamLabels,
      hasImportableStreamLabels,

      // use a computed, so it doesn't get stored
      hasHasImportableStreamLabels: computed(
        () => hasHasImportableStreamLabels.value,
      ),

      doImportStreamLabels,
      addLabelToScene,
      pinLabel,
      unpinLabel,
      restartSession,

      async saveSettings(streamLabel: TTT) {
        if (!settings.data) {
          return;
        }

        settings.data[streamLabel.name] = streamLabel.settings;

        await useStreamlabsFetch<StreamLabelsSettingsResponse>(
          "/api/v5/slobs/stream-labels/settings",
          {
            method: "POST",
            body: settings.data,
          },
        );
      },

      dispose() {
        authStore.socket.off("streamlabels", onStreamlabels);
      },
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    useStreamLabelsStore().dispose();
  });

  import.meta.hot.accept(
    acceptHMRUpdate(useStreamLabelsStore, import.meta.hot),
  );
}
